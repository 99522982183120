import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '@/utils/axios';
import { dispatch } from '../store';
import { onError } from './notification';
import { getSessionWithKey } from '@/utils/shopify';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  chart_data: {},
};

const slice = createSlice({
  name: 'liveOrders',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CHART DATA
    getLivefeedChartDataSuccess(state, action) {
      state.isLoading = false;
      state.chart_data = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getLivefeedChartData(params) {
  const { store_id, compare_to } = params;

  const secret = getSessionWithKey('secret');
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/fetch_livefeed_charts', {
        params: {
          store_id: store_id,
          secret,
          compare_to,
        },
      });

      dispatch(slice.actions.getLivefeedChartDataSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    }
  };
}
